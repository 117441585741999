/**
 * @generated SignedSource<<cbbf84610ad28c5d96d392a97f90de25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessUnitIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "classifications"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deleted"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "manualParagraphIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "statuses"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasManual",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "organisationIds",
  "variableName": "organisationIds"
},
v3 = [
  {
    "kind": "Variable",
    "name": "businessUnitIds",
    "variableName": "businessUnitIds"
  },
  {
    "kind": "Variable",
    "name": "classifications",
    "variableName": "classifications"
  },
  {
    "kind": "Variable",
    "name": "deleted",
    "variableName": "deleted"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "manualParagraphIds",
    "variableName": "manualParagraphIds"
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  },
  {
    "kind": "Variable",
    "name": "statuses",
    "variableName": "statuses"
  },
  {
    "kind": "Variable",
    "name": "userIds",
    "variableName": "userIds"
  }
],
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  (v7/*: any*/),
  (v5/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v10 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentsList_organisation"
          }
        ],
        "storageKey": null
      },
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "DocumentsList_query"
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "DocumentFilters_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasChildOrganisations",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentOrganisationId",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "DocumentPagination",
        "kind": "LinkedField",
        "name": "documentPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "classification",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "redirectUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "retentionPeriod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revisionDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualParagraph",
                "kind": "LinkedField",
                "name": "manualParagraph",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BusinessUnit",
        "kind": "LinkedField",
        "name": "businessUnits",
        "plural": true,
        "selections": (v10/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraphs",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v9/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "sortBy",
            "value": "name"
          },
          {
            "kind": "Literal",
            "name": "sortDirection",
            "value": "asc"
          },
          {
            "kind": "Literal",
            "name": "statuses",
            "value": [
              "active",
              "pending"
            ]
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v10/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb4d7be61147b35aeb06a2e7db7d02bd",
    "id": null,
    "metadata": {},
    "name": "DocumentsQuery",
    "operationKind": "query",
    "text": "query DocumentsQuery(\n  $businessUnitIds: [ID!]\n  $classifications: [String!]\n  $deleted: Boolean\n  $limit: Int\n  $manualParagraphIds: [ID!]\n  $organisationIds: [Int!]\n  $page: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n  $statuses: [String!]\n  $userIds: [ID!]\n) {\n  currentOrganisation {\n    hasManual\n    ...DocumentsList_organisation\n    id\n  }\n  ...DocumentsList_query_f22uR\n  ...DocumentFilters_query_2ND0HR\n}\n\nfragment DocumentFilters_query_2ND0HR on Query {\n  businessUnits(organisationIds: $organisationIds) {\n    databaseId\n    name\n    id\n  }\n  manualParagraphs {\n    databaseId\n    number\n    name\n    id\n  }\n  organisations {\n    databaseId\n    companyName\n    id\n  }\n  users(organisationIds: $organisationIds, statuses: [\"active\", \"pending\"], sortBy: \"name\", sortDirection: \"asc\") {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment Document_document on Document {\n  classification\n  databaseId\n  deletedAt\n  location\n  name\n  redirectUrl\n  retentionPeriod\n  revisionDate\n  status\n  type\n  updatedAt\n  manualParagraph {\n    databaseId\n    number\n    name\n    id\n  }\n  organisation {\n    companyName\n    id\n  }\n  user {\n    name\n    id\n  }\n}\n\nfragment Document_organisation on Organisation {\n  hasChildOrganisations\n  parentOrganisationId\n}\n\nfragment DocumentsList_organisation on Organisation {\n  ...Document_organisation\n  hasChildOrganisations\n  parentOrganisationId\n}\n\nfragment DocumentsList_query_f22uR on Query {\n  documentPagination(businessUnitIds: $businessUnitIds, classifications: $classifications, manualParagraphIds: $manualParagraphIds, deleted: $deleted, limit: $limit, organisationIds: $organisationIds, page: $page, query: $query, sortBy: $sortBy, sortDirection: $sortDirection, statuses: $statuses, userIds: $userIds) {\n    documents {\n      id\n      ...Document_document\n    }\n    totalCount\n  }\n}\n"
  }
};
})();

node.hash = "0bc4b963f7a8f5cf0b9bde764bfc3c9b";

module.exports = node;
